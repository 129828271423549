<template>
  <div>
    <div v-if="!product.voucher">
      <div class="row">
        <div class="col-sm-6">
          <div class="image">
            <img :src="imageBackground1" alt="course image" class="background1"/>
            <img :src="imageBackground2" alt="course image" class="background2"/>
            <img :src="product.thumbnail" alt="course image" class="course-image"/>
            <div class="shape"></div>
          </div>
        </div>
        <div class="col-sm-6">
          <h1 class="title">
            {{ product.title }}
          </h1>
          <div class="price">
            <div>
              <div
                  class="item-price d-inline"
                  v-if="product.price"
              >
                {{ product.price.toFixed(2) }} zł
              </div>
              <div
                  v-if="product.sale_price"
                  class="item-price-sale d-inline"
                  style="text-decoration: line-through"
              >
                {{ product.sale_price.toFixed(2) }} zł
              </div>
            </div>
            <div
                v-if="product.sale_price"
                class="lowest-price-text"
            >
              Najniższa cena z ostatnich 30dni: {{ product.lowest_price.toFixed(2) }} zł
            </div>
          </div>
          <div class="short-description" v-html="product.short_description"></div>
          <div style="padding-top: 15px; padding-bottom: 15px;" v-if="product.short_description">
            <a href="#description">Czytaj więcej</a>
          </div>
          <div class="buttons" v-if="!product.grayed">
            <b-button
                variant="primary"
                class="btn-cart move-cart"
                @click="pushDataLayerWhenUserAddProductToTheCart"
            >
              <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
              />
              <span>Dodaj do koszyka</span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 description" id="description" v-html="product.description"></div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-6 text-center">
          <a class="video-link maxcoach-box link-secret" href="https://youtu.be/auqSrgsRONo" target="_blank">
            <div class="video-poster">
              <div class="maxcoach-image">
                <img width="670" height="376" decoding="async" :src="demoImage" alt="1 (19)"  v-if="product.id != 11 && product.id != 10"/>
                <img width="670" height="376" decoding="async" :src="demoImage2" alt="1 (19)"  v-if="product.id == 11 || product.id == 10"/>
              </div>
              <div class="video-overlay"></div>
              <div class="video-button">
                <div class="video-play video-play-image">
                  <img width="100" height="70" decoding="async"  src="https://chemmaster.pl/wp-content/uploads/2019/11/icon-youtube-play.png" alt="icon-youtube-play" />
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-sm-6 headings">
          <div class="subheader">
            JAK WYGLĄDA NAUKA Z CHEMMASTEREM?
          </div>
          <h3>
            Wolisz konkrety<br />
            <span>Czy kota w worku?</span>
          </h3>
          <p>
            Kursy ChemMastera to więcej niż lekcje. To dokładny plan działania, profesjonalnie zmontowane video-lekcje
            (a nie nagrania ze spotkań na żywo!), czytelne, wysokiej jakości notatki w formacie gotowym do druku (PDF)
            oraz interaktywne testy z odpowiedziami i komentarzami, oraz interaktywnym śledzeniem postępów.
          </p>
          <p>Zobacz demo i <strong>poczuj chemię z ChemMasterem!</strong></p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm-12">
          <reviews/>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-sm-6">
          <div class="image">
            <img :src="imageVoucher" alt="course image" class="course-image"/>
          </div>
        </div>
        <div class="col-sm-6">
          <h1 class="title">
            {{ product.title }}
          </h1>
          <br />

          <b-form-group
              label="Wybierz wartość bonu"
              label-for="price"
              class="mb-1"
          >

            <b-form-select
                id="exam_year"
                v-model="voucher_price"
                :options="voucher_prices"
                trim
            />
          </b-form-group>

          <div class="short-description">
            {{ product.short_description }}
          </div>
          <div class="buttons">
            <b-button
                variant="primary"
                class="btn-cart move-cart"
                @click="pushDataLayerWhenUserAddProductToTheCart"
            >
              <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
              />
              <span>Dodaj do koszyka</span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 description" v-html="product.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BCardBody, BFormInput, BFormInvalidFeedback, BFormGroup, BFormSelect
} from 'bootstrap-vue'
import {useEcommerceUi} from '@/views/shop/useEcommerce'
import reviews from "@/views/Reviews.vue";
import {ValidationProvider} from "vee-validate";
import {useShopRemoteData} from "@/views/shop/useECommerceShop";

export default {
  components: {
    BFormSelect, ValidationProvider, BFormGroup, BFormInvalidFeedback,
    BFormInput,
    reviews,
    BCard,
    BCardText,
    BCardBody,
    BButton,
  },
  props: {
    index: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      product: {},
      voucher_price: 50,
      voucher_prices: [
        {value:50, text: '50zł'},
        {value:100, text: '100zł'},
        {value:200, text: '200zł'},
        {value:300, text: '300zł'},
        {value:400, text: '400zł'},
        {value:500, text: '500zł'},
        {value:1000, text: '1000zł'}
      ],
      chosenSubscription: {
        type: Number,
        default: null,
      },
      dataLayer: [],
      imageBackground1: require('@/assets/images/chemmaster/langinpage/maxcoach-shape-07.png'),
      imageBackground2: require('@/assets/images/chemmaster/langinpage/maxcoach-shape-08.png'),
      imageVoucher: require('@/assets/images/chemmaster/voucher/chemmaster karta upominkowa-1.jpg'),
      demoImage: require('@/assets/images/chemmaster/langinpage/Chemmaster YT - LO (1).png'),
      demoImage2: require('@/assets/images/chemmaster/langinpage/Chemmaster YT - szkoła podstawowa (1).png'),
    }
  },
  metaInfo() {
    return {
      title: this.product.title + " - Chemmaster.pl",
      meta: [
        { name: 'description', content: this.product.short_description},
        { property: 'og:title', content: this.product.title + " - Chemmaster.pl"},
        { property: 'og:site_name', content: 'ChemMaster.pl'},
        { property: 'og:description', content: this.product.short_description},
        {property: 'og:type', content: 'product'},
        {property: 'og:image', content: this.product.thumbnail }
      ]
    }
  },
  created() {
    this.fetchCourse(this.$route.params.slug)
  },
  methods: {
    async fetchCourse(slug) {
      this.$http.get(`${this.$store.state.apiDomain}/api/courses/${slug}`).then(response => {
        this.product = response.data;
        this.pushDataLayerWhenUserOpenProductDetails()
      });
    },
    pushDataLayerWhenUserOpenProductDetails() {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          currency: 'PLN',
          value: this.product.price,
          items: [{
            item_name: this.product.title,
            item_id: this.product.id,
            price: this.product.price,
            item_category: this.product.categories[0].name,
            item_list_name: `Kategoria: ${this.product.categories[0].name}`,
            item_list_id: `kategoria-${this.product.categories[0].name.toLowerCase()}`,
            index: this.$props.index,
            quantity: '1',
          }],
        },
      })
    },
    pushDataLayerWhenUserAddProductToTheCart() {
      this.handleCartActionClick(this.product, null, null, null, this.voucher_price)
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: 'PLN',
          value: this.product.price,
          items: [{
            item_name: this.product.title,
            item_id: this.product.id,
            price: this.product.price,
            item_category: this.product.categories[0].name,
            item_list_name: `Kategoria: ${this.product.categories[0].name}`,
            item_list_id: `kategoria-${this.product.categories[0].name.toLowerCase()}`,
            index: this.$props.index,
            quantity: '1',
          }],
        },
      })
    },
  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()
    const { getCartToken } = useShopRemoteData()
    const initializeCart = () => {
      getCartToken()
    }

    initializeCart()

    return {
      toggleProductInWishlist,
      handleCartActionClick,
    }
  }
}
</script>

<style lang="scss">
html{
  scroll-padding-top: 4rem;
}
img{
  max-width: 100%;
}
.title{
  font-size: 36px;
  line-height: 1.17em;
  font-weight: 700;
  letter-spacing: 0em;
  color: #333;
}
.price{
  color: #20ad96;
  font-size: 36px;
  .item-price-sale{
    color: #333;
    font-size: 28px;
  }
  .lowest-price-text{
    color: #333;
    font-size: 18px;
  }
}
.buttons{
  .btn.btn-primary{
    font-size: 14px;
    font-weight: 700;
    padding: 18px 30px;
    margin-top: 30px;
  }
}
.description {
  padding-top: 50px;
}
.video-link{
  position: relative;
  .video-poster {
    position: relative;
  }
  .video-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .video-button {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .maxcoach-image {
    box-shadow: 0px 130px 50px -100px rgba(51, 51, 51, 0.22);
  }
}
.headings{
  .subheader{
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 2em;
    letter-spacing: 2px;
    color: #8C89A2;
  }
  h3{
    font-size: 48px;
    line-height: 1.17em;
    color: #3F3A64;
    font-weight: 700;
    margin-bottom: 25px;
    span{
      font-weight: 400;
      color: #20AD96;
    }
  }
  p{
    font-size: 18px;
    line-height: 1.67em;
    width: 100%;
    max-width: 500px;
  }
}
.image {
  position: relative;
  text-align: center;
  img.course-image {
    width: 100%;
    max-width: 500px;
    margin-bottom: 10px;
    z-index: 10;
    position: relative;
    box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.25);
  }
  .shape{
    width: 62px;
    color: #ECC5AB;
    height: 62px;
    border-radius: 50%;
    border: 8px solid currentColor;
    bottom: 0;
    left: 25px;
    z-index: 15;
  }
  .background1, .background2, .shape{
    position: absolute;
    animation: Move 4s linear infinite;
  }
  .background1{
    top: 0;
    left: 0;
  }
  .background2{
    bottom: 0;
    right: 0;
  }

  .object {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  @keyframes Move {
    0%, 100% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(-10px, 0);
    }
    50% {
      transform: translate(-10px, -20px);
    }
    75% {
      transform: translate(0, -20px);
    }
  }
}
</style>
